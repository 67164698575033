// Use this for local
const dev = {
  url: {
    API_URL: "https://staging-api.implanteducationonline.net/",
  },
};

// Use this for deployed server (be it staging or prod )
const prod = {
  url: {
    API_URL: "https://api.implanteducationonline.net/", 
  },
};
export const config = process.env.NODE_ENV === "production" ? prod : dev;

export const API_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const AUTH_KEY = "auth";
export const ROLE = "role";

export const COPY_RIGHT_TEXT =
  "© 2002-2025 \u00A0 Califonria Implant Institute, Inc.";
export const WEBSITE_NAME = "www.implanteducation.net";
export const TRY_AGAIN = "Try Again";
export const SORT_KEYS = {
  ASC: "ASC",
  DESC: "DESC",
};

export const REC_PER_PAGE = 25;
export const CANCEL_GET_API_CALL = "Cancel get api";
export const SLIDE_BY = 300;
export const Roles = {
  admin: 1,
  dentist: 2,
};

export const ZOOM_CONSTANT = {
  MAX_ZOOM: 3,
  MIN_ZOOM: 1,
  ZOOM_STEP: 0.1,
};

export const DEFAULT_SUBCATEGORY_COLOR = "#818284";
export const DEFAULT_CARD_IMAGE_COLOR = "#818284";
export const FILE_MAX_SIZE = 5000000;
export const VIDEO_MAX_SIZE = 1024000000;
export const UPLOAD_LIMITS = {
  logo: 1,
  documents: 10,
  video: 1,
};

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "The Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Republic of the",
  "Congo, Democratic Republic of the",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "The Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Federated States of Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const SAAS_DATA_SAVE_SUCCESS = "Data saved successfully.";

export const OTHERCARDS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const AMERICANEXPRESS = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CVC = [/[0-9]/, /\d/, /\d/, /\d/];
export const ROW_PER_PAGE_OPTIONS = [25, 50, 100];
export const RESET_PASSWORD_TEXT =
  "In order to protect your account, make sure your password :";
export const MIN_PASSWORD_LENGTH_TEXT = "is longer than 7 characters";
export const PASSWORD_LETTER_CONSTRAINT =
  "should contain at least one lower case letter (a-z), one upper case letter (A-Z) and one number";
export const PASSWORD_CONSTRAINTS_FAILED_MESSAGE =
  "Your password should contain minimum 8 characters, at least one lower case letter (a-z), one upper case letters (A-Z) and one number";
export const PASSWORD_RESET_SUCCESS_MESSAGE =
  "Your password has been updated successfully";
export const CATEGORY_PARENT = {
  EXTERNSHIP: "Live-Patient Surgical Externships",
  ONLINE_PROGRAM: "Online CE Programs",
  CADAVER_PROGRAM: "Hands-On Cadaver Programs",
};
export const CATEGORY_TYPE = {
  EXTERNSHIP: "ciicourse",
  ONLINE_PROGRAM: "onlinePrograms",
  CADAVER_PROGRAM: "handsOnProgram",
};

export const REGISTER_CATEGORY_DETAILS = {
  "Basic to Intermediate Surgical Externship": {
    TUTION_DATES: [
      "June 2-7, 2025",
      "September 29 – October 4, 2025",
      "January 19-24, 2026",
      "June 8-13, 2026",
      "October 5-10, 2026",
    ],
    TUTION_FEES: {
      "CLINICAL PARTICIPATION": 13995,
      "CLINICAL OBSERVATION ONLY": 5995,
      "DENTAL ASSISTANT": 1995,
    },
  },
  "All-on-4 & Full Arch Surgical Externship": {
    TUTION_DATES: [
      "August 11-16, 2025",
      "December 8–13, 2025",
      "April 20-25, 2026",
      "August 24-29, 2026",
      "December 7-12, 2026",
    ],
    TUTION_FEES: {
      "CLINICAL PARTICIPATION": 15995,
      "CLINICAL OBSERVATION ONLY": 5995,
      "DENTAL ASSISTANT": 1995,
    },
  },
  "Advanced Bone Grafting Program": {
    TUTION_DATES: ["October 23-25, 2025", "November 5-7, 2026"],
    TUTION_FEES: {
      TOTAL: 4995,
    },
  },
  "Zygomatic & Pterygoid Surgical Externship": {
    TUTION_DATES: ["June 26 - 28, 2025", "June 25 - 27, 2026"],
    TUTION_FEES: {
      TOTAL: 4995,
    },
  },
  "Peri-implantitis Management - 50 CE": {
    TUTION_FEES: {
      TOTAL: 995,
    },
  },
  "All-on-X Master Clinician - 50 CE": {
    TUTION_FEES: {
      TOTAL: 1495,
    },
  },
};
