import { SEPERATOR_GREY } from "../../consts/colors";

export const styles = {
  modal: {
    width: "100%",
    height: "100vh",
    top: "0",
    bottom: "0",
    backgroundColor: "transparent",
  },
  closeButton: {
    color: SEPERATOR_GREY,
    fontWeight: "Bold",
    fontSize: "30px",
  },
  closeIconButton: {
    padding:"0px"
  },
  modalBox: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  videoStyle:{
    maxHeight: "60vh",
     maxWidth: "90vw",
     height: "60vh"
  }
}; 
