const iconStyle = {
  height: "20px",
  width: "20px",
};
const styles = {
  container: {
    width: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  sliderContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '0 8px',
    width:'100%'
  },
  slider: {
    flex: 1,
    '& .MuiSlider-root': {
      width: '100%',
    }
  },
  iconButton: {
    padding: '4px',
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    }
  },
  rotateButton: {
    padding: '4px',
    backgroundColor: '#f5f5f5',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    }
  },
  zoomInfoContainer: {
    marginTop: "32px",
    display: "flex",
    gap: "16px",
    alignItems: "center",
    padding: "0px 20px",
    width:'100%'
  },
  sliderBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flex: 1,
    width:'100%'
  },
  zoomIcon: {
    cursor: "pointer",
    ...iconStyle,
  },
  disabledIcon: {
    ...iconStyle,
    cursor: "default",
  },
  percentageText: {
    fontSize: "14px",
    minWidth: "36px",
  },
};

export default styles;