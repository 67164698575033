import { Height } from "@mui/icons-material";
import { WHITE } from "../../consts/colors";

export const styles = {
  topRightBoxStyles: {
  },
  iconStyles: {
    color: WHITE,
  },
  imageStyles: {
    maxWidth: "100%",
    objectFit: "contain",
    maxHeight: "60vh"
  },
};
