import accountsImage from "../../img/accounts.svg";
import coursesImage from "../../img/courses.svg";
import onlineProgramsImage from "../../img/OnlinePrograms.png";
import handsOnImage from "../../img/HandsOn.png";
import saasIcon from "../../img/saas.svg";
import userIcon from "../../img/users.svg";
import OnlineProgramRequest from "../../img/OnlineProgramRequests.svg";
import PurchaeOnlineProgams from "../../img/purchaeonlineprograms.svg";
import navigations from "../../routes/routeNames";

export const sideBarIcons = [
  {
    img: accountsImage,
    navigateTo: navigations.ACCOUNTS,
    name: "Accounts",
    id: 1,
  },
  {
    img: coursesImage,
    navigateTo: navigations.LIVE_EXTERNSHIP,
    name: "Live Patient Externships",
    id: 2,
  },
  {
    img: handsOnImage,
    navigateTo: navigations.CADAVER_PROGRAM,
    name: "Hands-On Cadaver Programs",
    id: 3,
  },
  {
    img: onlineProgramsImage,
    navigateTo: navigations.ONLINE_PROGRAM,
    name: "Online Programs",
    id: 4,
  },

  {
    img: OnlineProgramRequest,
    navigateTo: navigations.ONLINE_PROGRAM_REQUEST,
    name: "Online Program CE Requests",
    id: 5,
  },

  {
    img: PurchaeOnlineProgams,
    navigateTo: navigations.PURCHASE_ONLINE_PROGRAMS,
    name: "Purchased Programs",
    id: 6,
  },

  {
    img: saasIcon,
    navigateTo: navigations.SAAS,
    name: "saas",
    id: 7,
  },
  {
    img: userIcon,
    navigateTo: navigations.USERS,
    name: "Users",
    id: 8,
  },
];
